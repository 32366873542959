import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router"; // 引入路由配置
// import { getProducts, accessToken } from "@/api";

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router, // 使用路由
  created: async function () {
    // const res = await fetch('shopify:admin/api/graphql.json', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     query: `
    //       query GetProduct($id: ID!) {
    //         product(id: $id) {
    //           title
    //         }
    //       }
    //     `,
    //     variables: {id: 'gid://shopify/Product/1234567890'},
    //   }),
    // });
    // const {data} = await res.json();
    // console.log(data);
    // 封印
    const urlParams = new URLSearchParams(window.location.search);
      const shop = urlParams.get("shop");
      localStorage.setItem('shopName',shop)
      // localStorage.setItem('shopName','linkbuyaaa.myshopify.com')
      // console.log(shop,'店铺名称')
      // 获取完整路径，例如 '/store/linkbuyaaa/apps/linkbuy'
  },

  render: (h) => h(App),
}).$mount("#app");

