<template>
  <div class="ProductSetbox">
    <div class="ProductSetbox_tit">Orders</div>
    <div class="ProductSetbox_tip">
      <div style="display: flex; align-items: flex-start">
        <div class="ProductSetbox_tipsvg">
          <svg
            viewBox="0 0 20 20"
            class="Polaris-Icon__Svg"
            focusable="false"
            aria-hidden="true"
          >
            <path d="M11 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path>
            <path
              d="M10.75 9.25a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0v-4.5Z"
            ></path>
            <path
              fill-rule="evenodd"
              d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-1.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
            ></path>
          </svg>
        </div>

        <div class="ProductSetbox_tiptit">
          LinkBuy does not sell any counterfeit or fake products and conducts
          all transactions in compliance with local laws. If any of your product
          sourcing requests involve branded or patented designs, please contact
          the corresponding brand owners for authorization.<br />
          LinkBuy bears no responsibility for non-compliant requests in this
          regard; all liabilities must be assumed by the merchant themselves.
        </div>
      </div>
      <!-- <i
        class="el-icon-close"
        style="font-size: 20px"
      ></i> -->
    </div>
    <div class="ProductSetbox_conbox">
      <div class="orderselectbox">
        <div
          class="orderselectbox_item"
          @click="clickorderselect(index)"
          :class="{ active: active == index }"
          v-for="(item, index) in orderselect"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="ProductSetbox_con">
        <div class="ProductSetbox_con2">
          <el-select
            v-model="value"
            size="medium"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="ProductSetbox_con1">
          <el-input
            v-model="input"
            placeholder="Search with product keywords or order number"
            size="medium"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            ></i
          ></el-input>
        </div>
        <div class="ProductSetbox_conselect">
          <el-input
            v-model="params.min_price"
            size="medium"
            placeholder="Start"
          ></el-input>
          <img
            src="../assets/timearrow.png"
            alt=""
          />
          <el-input
            v-model="params.max_price"
            size="medium"
            placeholder="End"
          ></el-input>
        </div>
        <div class="ProductSetbox_cont">
          <div
            class="ProductSetbox_con3"
            @click="Searchclick"
          >
            Search
          </div>
          <div
            class="ProductSetbox_con4"
            @click="clearclick"
          >
            Clear
          </div>
          <div
            class="ProductSetbox_con4 one"
            @click="allDelivery"
          >
            Batch Delivery
          </div>
          <div
            class="ProductSetbox_con4 two"
            @click="Delivery('all')"
          >
            Batch Delivery
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        empty-text="no data..."
        :data="list"
        tooltip-effect="dark"
        class="table"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
        <!-- sortable -->
        <el-table-column
          label="Order Number"
          min-width="100"
          prop="order_no"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.order_no }}
          </template> -->
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="Order Date"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="product"
          min-width="200"
        >
          <template slot-scope="scope">
            <div class="productsrcbox">
              <div class="productsrc">
                <img
                  :src="scope.row?.goods_image"
                  alt=""
                />
              </div>
              <div class="productname">
                {{ scope.row.goods_name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="total amount"
          min-width="130"
        >
          <template slot-scope="scope">
            ${{ scope.row.currency_amount }}
          </template>
        </el-table-column>
        <el-table-column
          label="Action"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.order_status == 1"
              @click.native.prevent="Delivery(scope.row.id)"
              type="text"
              size="small"
            >
              pay
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="count > 0"
        class="pagination pagination1"
        background
        layout="prev, pager, next"
        :total="count"
        @current-change="currentchange"
      >
      </el-pagination>
      <div
        class="pro_con"
        v-if="list.length > 0"
      >
        <div
          class="pro_item"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="pro_left">
            <img
              :src="item.goods_image"
              alt=""
            />
          </div>
          <div class="pro_right">
            <div class="pro_right1">{{ item.goods_name }}</div>
            <div class="pro_right2">{{ item.order_no }}</div>
          <div class="pro_right3">{{ item.createtime }}</div>
          <div class="pro_right4">
            <div>
              ${{ item.currency_amount }}
            </div>
            <el-button
              v-if="item.order_status == 1"
              @click.native.prevent="Delivery(item.id)"
              type="text"
            >
              pay
            </el-button>
          </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="datanull"
      >
        no data...
      </div>
      <el-pagination
        v-if="count > 0"
        class="pagination pagination2"
        small
        layout="prev, pager, next"
        :total="count"
        @current-change="currentchange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { borderquery, getbordersend, getpaypalpay } from "@/api";

export default {
  data() {
    return {
      count: 0,
      list: [],
      active: 0,
      tabPosition: "left",
      input: "",
      orderselect: [{ name: "All" }, { name: "To pay" }, { name: "To ship" }],
      options: [
        {
          label: "Product keywords",
          value: "name",
        },
        {
          label: "Shopify order number",
          value: "number",
        },
      ],
      value: "name",

      multipleSelection: [],
      params: {},
      timers: null,
    };
  },
  created() {
    this.borderquery();
  },

  methods: {
    allDelivery() {
      if (this.multipleSelection.length > 0) {
        this.Delivery();
      } else {
        this.$message({
          message: "Please select the shipping order",
          type: "warning",
        });
      }
    },
    currentchange(e) {
      console.log(e);
      this.params.page = e;
      this.borderquery();
    },
    Searchclick() {
      if (this.value == "name") {
        this.params.name = this.input;
      } else {
        this.params.number = this.input;
      }
      // console.log(this.params)
      this.borderquery();
    },
    clearclick() {
      this.active = 0;
      this.input = "";
      this.params = {};
      this.borderquery();
    },
    borderquery() {
      borderquery(this.params).then((res) => {
        console.log(res);
        this.list = res?.info?.data;
        this.count = res?.info?.total;
      });
    },
    clickorderselect(index) {
      this.active = index;

      if (index == 0) {
        this.params.page = 1;
        if (Object.prototype.hasOwnProperty.call(this.params, "status")) {
          delete this.params.status;
        }
      } else {
        this.params.status = index;
        this.params.page = 1;
      }
      if (this.timers != null) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.borderquery();
      }, 500);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    Delivery(id) {
      console.log(id);
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((item) => {
          ids.push(item.id);
        });
      }
      getbordersend({
        order_ids: ids.join(","),
      }).then((res) => {
        if (res.code == 1) {
          getpaypalpay({ order_ids: res.info.id }).then((data) => {
            window.location.href = data.info.url;
          });
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .has-gutter tr th {
  background-color: #f1f1f1;
}
/* ::v-deep .has-gutter tr th .cell{
  white-space: nowrap;
} */
.ProductSetbox {
  padding: 0 24px;
  background: #f1f1f1;
  min-height: 100vh;
}
.ProductSetbox_tit {
  color: rgb(48, 48, 48);
  font-size: 20px;
  font-weight: 700;
  padding: 24px 0;
}
.ProductSetbox_con {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 8px;
}
.ProductSetbox_con1 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.ProductSetbox_con2 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.ProductSetbox_con1 span {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: rgb(48, 48, 48);
  font-size: 12px;
  margin-right: 8px;
}
.ProductSetbox_con2 span {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: rgb(48, 48, 48);
  font-size: 12px;
  margin-right: 8px;
}
.ProductSetbox_con3 {
  font-size: 12px;
  border-radius: 8px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  padding: 6px 12px;
  cursor: pointer;
}
.ProductSetbox_con4 {
  color: rgb(48, 48, 48);
  font-size: 12px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgb(48, 48, 48);
  padding: 6px 12px;
  cursor: pointer;
}
.ProductSetbox_conbox {
  background-color: #fff;
  padding: 10px 0;
  border-radius: 8px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: rgb(48, 48, 48);
  border-color: rgb(48, 48, 48);
}
::v-deep .el-checkbox__inner:hover {
  border-color: rgb(48, 48, 48);
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: rgb(48, 48, 48);
  border-color: rgb(48, 48, 48);
}
.procon {
  display: flex;
  flex-wrap: wrap;
}
.procon_item {
  width: 14%;
  padding: 10px 15px 0;
}
.procon_itemimgbox {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.procon_itemimgbox > div {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.04);
  position: absolute;
}
.procon_itemimgbox img {
  width: 100%;
}
.procon {
  height: 475px;
  overflow-y: scroll;
}
/* ::v-deep .el-tabs__content{

} */
.procon_itemtitle {
  margin-top: 16px;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep .el-tabs__nav-scroll {
  overflow-y: auto;
}
::v-deep .el-tabs__nav-scroll::-webkit-scrollbar {
  display: none;
}
::v-deep .el-tabs__nav-next {
  opacity: 0;
}
::v-deep .el-tabs__nav-prev {
  opacity: 0;
}
.ProductSetbox_tip {
  width: 100%;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 15px;
}
.ProductSetbox_tipsvg {
  background-color: rgb(145, 208, 255);
  border-radius: 8px;
  padding: 4px;
  margin-right: 8px;
}
.ProductSetbox_tipsvg svg {
  width: 20px;
  height: 20px;
}
.ProductSetbox_tip {
  font-size: 13px;
  font-weight: 450;
}
.orderselectbox {
  display: flex;
  padding: 8px;
  overflow-x: auto;
}
.orderselectbox_item {
  font-size: 12px;
  color: rgb(74, 74, 74);
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 5px;
  flex-shrink: 0;
}
.orderselectbox_item.active {
  background-color: rgba(0, 0, 0, 0.08);
}
.ProductSetbox_conselect {
  display: flex;
  margin-bottom: 8px;
}
.ProductSetbox_conselect .el-input {
  width: 120px;
}
.ProductSetbox_con > div {
  margin-right: 15px;
}
.ProductSetbox_cont {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.ProductSetbox_cont > div {
  margin-right: 10px;
}
.pagination {
  margin-top: 6px;
  text-align: right;
}
.productsrc {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  box-shadow: rgba(0, 0, 0, 0.08) 0;
  flex-shrink: 0;
  margin-right: 12px;
}
.productsrc img {
  max-width: 100%;
  max-height: 100%;
}
.productsrcbox {
  display: flex;
  align-items: center;
}
.productname {
  font-size: 13px;
  font-weight: 450;
  line-height: 20px;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
}
.pro_con {
  display: none;
  flex-wrap: wrap;
}
.pro_item {
  width: 100%;
}

.pro_left {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.08) 0;
  flex-shrink: 0;
  margin-right: 12px;
}
.pro_item {
  padding: 12px 10px;
  border-bottom: 1px solid rgb(227, 227, 227);
  display: flex;
}
.pro_left img {
  max-width: 100%;
  max-height: 100%;
}
.pro_right1 {
  font-size: 14px;
  font-weight: 550;
}
.pro_right > div {
  margin-bottom: 3px;
}
.datanull {
  color: rgb(97, 97, 97);
  text-align: center;
  margin-top: 20px;
}
.pro_right2 {
  font-size: 12px;
  color: rgb(48, 48, 48);
  font-weight: 450;
}
.pro_right3 {
  font-size: 12px;
  color: rgb(97, 97, 97);
  font-weight: 450;
}
.pro_right4 div {
  font-size: 15px;
  /* color: rgb(97, 97, 97); */
  font-weight: 450;
}
.pro_right4{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}
.datanull {
  display: none;
}
.pagination2 {
  display: none;
}
.two{
  display: none;
}
@media (max-width: 842px) {
  .ProductSetbox_con2 {
    width: 100%;
  }
  .ProductSetbox_con2 .el-select {
    width: 100%;
  }
  .ProductSetbox_con1 {
    width: 100%;
  }
}
@media (max-width: 475px) {
  .datanull {
    display: block;
  }
  .ProductSetbox {
    padding: 0 12px;
  }

  .table {
    display: none;
  }
  .pro_con {
    display: flex;
  }
  .pagination2 {
    display: block;
  }
  .pagination1 {
    display: none;
  }
  .two{
    display: block;
  }
  .one{
    display: none
  }
}
</style>
