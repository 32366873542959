var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ProductSetbox"},[_c('div',{staticClass:"ProductSetbox_tit"},[_vm._v("Product list")]),_c('div',{staticClass:"ProductSetbox_conbox"},[_c('div',{staticClass:"ProductSetbox_con"},[_c('div',{staticClass:"ProductSetbox_con1"},[_c('span',[_vm._v(" Product name")]),_c('el-input',{attrs:{"placeholder":"product name","size":"medium"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1),_c('div',{staticClass:"ProductSetbox_con2"},[_c('span',[_vm._v("Category")]),_c('el-select',{attrs:{"placeholder":"Category","size":"medium"},on:{"change":_vm.change},model:{value:(_vm.optionvalue),callback:function ($$v) {_vm.optionvalue=$$v},expression:"optionvalue"}},_vm._l((_vm.options),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1),_c('div',{staticClass:"ProductSetbox_con3",on:{"click":function($event){return _vm.BangDingclick()}}},[_vm._v(" Link with LinkBuy ")])]),_c('el-table',{ref:"multipleTable",staticClass:"table",staticStyle:{"width":"100%"},attrs:{"empty-text":"no data...","data":_vm.filteredProducts,"tooltip-effect":"dark"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":"Product","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"productsrcbox"},[_c('div',{staticClass:"productsrc"},[_c('img',{attrs:{"src":scope.row?.images[0]?.src,"alt":""}})]),_c('div',{staticClass:"productname"},[_vm._v(" "+_vm._s(scope.row.title)+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"Statuscolor",style:({
              background:
                scope.row.status == 'active'
                  ? 'rgb(175, 254, 191)'
                  : 'rgb(213, 235, 255)',
              color:
                scope.row.status == 'active'
                  ? 'rgb(1, 75, 64)'
                  : 'rgb(0, 58, 90)',
            })},[_vm._v(" "+_vm._s(scope.row.status)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"product_type","label":"Type"}}),_c('el-table-column',{attrs:{"prop":"category_name","label":"Associated categories","min-width":"200"}})],1)],1),(_vm.filteredProducts.length > 0)?_c('div',{staticClass:"pro_con"},_vm._l((_vm.filteredProducts),function(item,index){return _c('div',{key:index,staticClass:"pro_item",on:{"click":function($event){return _vm.middleopen(item.id)}}},[_c('div',{staticClass:"pro_left"},[_c('img',{attrs:{"src":item.images[0]?.src,"alt":""}})]),_c('div',{staticClass:"pro_right"},[_c('div',{staticClass:"pro_right1"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"pro_right2"},[_vm._v(_vm._s(item.product_type))]),_c('div',{staticClass:"pro_right3"},[_vm._v(_vm._s(item.category_name))]),_c('div',{staticClass:"Statuscolor",style:({
            background:
              item.status == 'active'
                ? 'rgb(175, 254, 191)'
                : 'rgb(213, 235, 255)',
            color:
              item.status == 'active' ? 'rgb(1, 75, 64)' : 'rgb(0, 58, 90)',
          })},[_vm._v(" "+_vm._s(item.status)+" ")])])])}),0):_c('div',{staticClass:"datanull"},[_vm._v(" no data... ")]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"70%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-tabs',{staticStyle:{"height":"50vh"},attrs:{"tab-position":_vm.tabPosition}},_vm._l((_vm.CategoryArr),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.title}},[_c('div',{staticClass:"procon"},_vm._l((item.allGrandchildren),function(items,indexs){return _c('div',{key:indexs,staticClass:"procon_item",class:{ active: items.id == _vm.id },on:{"click":function($event){return _vm.procon_itemclick(items.id)}}},[_c('div',{staticClass:"procon_itemimgbox"},[_c('div'),_c('img',{attrs:{"src":items.big_image,"alt":""}})]),_c('div',{staticClass:"procon_itemtitle"},[_vm._v(_vm._s(items.title))])])}),0)])}),1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"disabled":_vm.id==''},on:{"click":_vm.Preview}},[_vm._v("Preview category")]),_c('el-button',{staticClass:"submitbut",attrs:{"disabled":_vm.id == '',"type":"primary"},on:{"click":function($event){return _vm.submitaddproduct()}}},[_vm._v("Confirm selection")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }