<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/ProductList">Products</router-link>
      <router-link to="/ProductList/recommendations">recommendations</router-link>
    </nav> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
      <!-- Shopify Admin 侧边栏导航菜单 -->
    <ui-nav-menu>
      <a href="/" rel="home">Home</a>
      <a href="/Login">Login</a>
      <a href="/ProductSet">Products</a>
      <a href="/ProductOrders">ProductOrders</a>
    </ui-nav-menu>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  
}
</script>

<style>
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
