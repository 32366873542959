/*
 * @Author: Linkbuy
 * @Date: 2019-06-28 09:59:48
 * @Last Modified by: luoyang
 * @Last Modified time: 2019-08-27 15:24:53
 */
'use strict'
import Vue from 'vue'
import axios from 'axios'
import router from '@/router' // 导入 Vue Router 实例
import { Message } from 'element-ui'; // 引入全局的 Element UI
// const baseURL =
//   window.location.origin.indexOf('localhost') != -1
//     ? 'https://aaaa.2p44.com'
//     : window.location.origin
const baseURL ='https://store.2p44.com'
// const baseURL ='https://france.linkbuy.com/'
const config = {
  // baseURL: "http://139.129.24.43",
  // baseURL: "https://shop.itqiyi.com",
  // baseURL: "https://store.2p44.com",
  // baseURL: "https://store.linkbuy.com",
  // baseURL: "https://aaaa.linkbuy.com",
  baseURL: baseURL,
  timeout: 60 * 1000
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  (config) => {
    // 从 localStorage 获取 token 和 shopName
    const token = localStorage.getItem('token');
    const shopName = localStorage.getItem('shopName');
    config.headers['lang'] = 'en'
    config.headers['Currencyjc'] = 'USD1'

    // 确保 config.headers 和 config.headers.common 存在
    // if (!config.headers) {
    //   config.headers = {};
    // }
    // if (!config.headers.common) {
    //   config.headers.common = {};
    // }

    // 设置 Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.params = {
        ...config.params, // 保留已有的参数
        api_token: token,     // 自动附加 token 参数
      };
    }

    // 如果有 shopName，添加到请求参数中
    if (shopName) {
      config.params = {
        ...config.params, // 保留已有的参数
        shop: shopName,   // 自动附加 shop 参数
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


_axios.interceptors.response.use(
  (response) => {
    // console.log(response, "data");
    if (response.data.code == 1) {
      return response.data
    } else {
      return response.data
    }
  },
  (error) => {
    // console.log(error, "token");

    if (error.response.data.code == 401) {
      // Notify('token expired, please log in again')
      Message({
        message: "token expired, please log in again",
        type: "warning",
      });
      setTimeout(() => {
        // store.dispatch('user/logout') // 使用 Vuex store 实例
        router.push({ name: 'Login' }) // 使用 Vue Router 实例
      }, 2000)
    } else if (error.response.data.code == 403) {
      window.location.href = 'https://www.linkbuy.com'
    } else {
      // const status = error.response.data.msg
      // Notify(status)
      return error.response.data
    }
  }
)


export const getAxios = () => {
  return _axios
}

Plugin.install = function(Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
