import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue'; // 替换为你自己的组件
import ProductList from '../components/ProductList.vue'; // 替换为你自己的组件
import recommendations from '../components/recommendations.vue'; // 假设你有一个产品详情组件
import ProductSet from '../views/ProductSet.vue'; // 假设你有一个产品详情组件
import ProductOrders from '../views/ProductOrders.vue'; // 假设你有一个产品详情组件
import index from '../views/index.vue'; // 假设你有一个产品详情组件
import Login from '../views/Login.vue'; // 假设你有一个产品详情组件
import shopify from '../views/shopify.vue'; // 假设你有一个产品详情组件

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/ProductSet',
      name: 'ProductSet',
      component: ProductSet
    },
    {
      path: '/ProductOrders',
      name: 'ProductOrders',
      component: ProductOrders
    },
    {
      path: '/shopify',
      name: 'shopify',
      component: shopify
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login
    },
    {
      path: 'HelloWorld',
      name: 'HelloWorld',
      component: HelloWorld
    },
    {
      path: '/ProductList',
      name: 'ProductList',
      component: ProductList,
      children: [
        {
          path: 'recommendations', // 子路由路径
          name: 'recommendations', // 子路由名称
          component: recommendations, // 对应的组件
        }
      ]
    }
  ]
});
