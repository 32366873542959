<template>
  <div class="ProductSetbox">
    <div class="ProductSetbox_tit">Product list</div>
    <div class="ProductSetbox_conbox">
      <div class="ProductSetbox_con">
        <div class="ProductSetbox_con1">
          <span> Product name</span>
          <el-input
            v-model="input"
            placeholder="product name"
            size="medium"
          ></el-input>
        </div>
        <div class="ProductSetbox_con2">
          <span>Category</span>
          <el-select
            v-model="optionvalue"
            placeholder="Category"
            size="medium"
            @change="change"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="ProductSetbox_con3"
          @click="BangDingclick()"
        >
          Link with LinkBuy
        </div>
      </div>
      <el-table
        empty-text="no data..."
        ref="multipleTable"
        class="table"
        :data="filteredProducts"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
          <!-- sortable -->

        <el-table-column
          label="Product"
          min-width="200"
        >
          <template slot-scope="scope">
            <div class="productsrcbox">
              <div class="productsrc">
                <img
                  :src="scope.row?.images[0]?.src"
                  alt=""
                />
              </div>
              <div class="productname">
                {{ scope.row.title }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Status"
        >
          <template slot-scope="scope">
            <div
              class="Statuscolor"
              :style="{
                background:
                  scope.row.status == 'active'
                    ? 'rgb(175, 254, 191)'
                    : 'rgb(213, 235, 255)',
                color:
                  scope.row.status == 'active'
                    ? 'rgb(1, 75, 64)'
                    : 'rgb(0, 58, 90)',
              }"
            >
              {{ scope.row.status }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="product_type"
          label="Type"
        >
        </el-table-column>
        <el-table-column
          prop="category_name"
          label="Associated categories"
          min-width="200"
        >
        </el-table-column>
      </el-table>

      <!-- <div style="margin-top: 20px">
        <el-button @click="toggleSelection([tableData[1], tableData[2]])"
          >切换第二、第三行的选中状态</el-button
        >
        <el-button @click="toggleSelection()">取消选择</el-button>
      </div> -->
    </div>
    <div
      class="pro_con"
      v-if="filteredProducts.length > 0"
    >
      <div
        class="pro_item"
        v-for="(item, index) in filteredProducts"
        :key="index"
        @click="middleopen(item.id)"
      >
        <div class="pro_left">
          <img
            :src="item.images[0]?.src"
            alt=""
          />
        </div>
        <div class="pro_right">
          <div class="pro_right1">{{ item.title }}</div>
          <div class="pro_right2">{{ item.product_type }}</div>
          <div class="pro_right3">{{ item.category_name }}</div>
          <div
            class="Statuscolor"
            :style="{
              background:
                item.status == 'active'
                  ? 'rgb(175, 254, 191)'
                  : 'rgb(213, 235, 255)',
              color:
                item.status == 'active' ? 'rgb(1, 75, 64)' : 'rgb(0, 58, 90)',
            }"
          >
            {{ item.status }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="datanull"
    >
      no data...
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-tabs
        :tab-position="tabPosition"
        style="height: 50vh"
      >
        <el-tab-pane
          :label="item.title"
          v-for="(item, index) in CategoryArr"
          :key="index"
        >
          <div class="procon">
            <div
              class="procon_item"
              :class="{ active: items.id == id }"
              @click="procon_itemclick(items.id)"
              v-for="(items, indexs) in item.allGrandchildren"
              :key="indexs"
            >
              <div class="procon_itemimgbox">
                <div></div>
                <img
                  :src="items.big_image"
                  alt=""
                />
              </div>
              <div class="procon_itemtitle">{{ items.title }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="Preview" :disabled="id==''">Preview category</el-button>
        <el-button
          class="submitbut"
          :disabled="id == ''"
          type="primary"
          @click="submitaddproduct()"
          >Confirm selection</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  shopifyproduct,
  shopifycategory,
  getCategoryIndex,
  addProduct,
} from "@/api";
export default {
  data() {
    return {
      optionvalue: "",
      tabPosition: "left",
      dialogVisible: false,
      input: "",
      options: [],
      multipleSelection: [],
      product: [],
      CategoryArr: [],
      params: {},
      id: "",
      middleopenid:''
    };
  },
  computed: {
    filteredProducts() {
      // 根据搜索框内容进行过滤

      if (!this.input) {
        return this.product; // 如果没有输入，返回全部数据
      }
      const searchTerm = this.input.toLowerCase(); // 转为小写，忽略大小写
      return this.product.filter((product) => {
        return (
          product.title?.toLowerCase().includes(searchTerm) || // 过滤条件：根据标题搜索
          product.product_type?.toLowerCase().includes(searchTerm) // 也可以按类型搜索
          // || product.category_name?.toLowerCase().includes(searchTerm) // 也可以按分类搜索
        );
      });
    },
  },
  created() {
    this.shopifyproduct();
    this.shopifycategory();
    this.getCategoryIndex();
  },
  methods: {
    Preview() {
      const width = 375; // 设置移动端窗口宽度
      const height = 667; // 设置移动端窗口高度

      // 居中显示新窗口
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      // 打开新窗口，设置大小、位置，去掉可调整大小功能
      window.open(
        "https://store.2p44.com/searchlist?search="+this.id, // 你想打开的URL
        "_blank", // 打开方式，_blank表示新窗口
        `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no`
      );
    },
    submitaddproduct() {
      if (this.id == "") {
        return;
      }
      let product_id = [];
      if(this.middleopenid){
        product_id.push(this.middleopenid);
      }else{
        this.multipleSelection.forEach((item) => {
          product_id.push(item.id);
        });
      }

      addProduct({
        product_id: product_id.join(","),
        cat_id: this.id,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$message({
            showClose: true,
            message: "Binding successful",
            type: "success",
          });
          this.shopifyproduct();
          this.dialogVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: "go wrong",
            type: "error",
          });
        }
      });
    },
    middleopen(id) {
      console.log(id)
      this.middleopenid=id;
      this.id = "";
      this.$refs.multipleTable.clearSelection();
      console.log(this.multipleSelection);
      this.dialogVisible = true;
    },
    handleClose(done){
      this.middleopenid=''
      done();
    },
    BangDingclick() {
      this.id = "";
      if (this.multipleSelection.length > 0) {
        this.dialogVisible = true;
      } else {
        this.$message({
          message: "Please check the item you want to bind",
          type: "warning",
        });
      }
      // this.multipleSelection = []
      // console.log(this.multipleSelection)
      // if(){
      // }
    },
    procon_itemclick(id) {
      // console.log(id)
      this.id = id;
    },
    change() {
      console.log(this.optionvalue);
      if (this.optionvalue == "All") {
        this.params.product_type = "";
      } else {
        this.params.product_type = this.optionvalue;
      }
      this.shopifyproduct();
    },
    shopifyproduct() {
      shopifyproduct(this.params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.product = res?.info?.products;
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 分类筛选
    shopifycategory() {
      shopifycategory().then((res) => {
        // console.log(res,'打印分类');
        if (res.code == 1) {
          this.options = res.info;
          this.options.unshift("All");
        }
      });
    },
    getCategoryIndex() {
      getCategoryIndex().then((res) => {
        // console.log(res.info.request, "打印分类");
        if (res.code == 1) {
          this.CategoryArr = res?.info?.request;
        }
      });
    },
    // handleClose(done) {
    //   this.$confirm("确认关闭？")
    //     .then(() => {
    //       done();
    //     })
    //     .catch(() => {});
    // },

    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped>
::v-deep .has-gutter tr th {
  background-color: #f1f1f1;
}
/* ::v-deep .has-gutter tr th .cell{
  white-space: nowrap;
} */
.ProductSetbox {
  padding: 0 24px;
  background: #f1f1f1;
  min-height: 100vh;
}
.ProductSetbox_tit {
  color: rgb(48, 48, 48);
  font-size: 20px;
  font-weight: 700;
  padding: 24px 0;
}
.ProductSetbox_con {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding: 0 10px;
  margin-bottom: 10px;
}
.ProductSetbox_con > div {
  margin-right: 15px;
}
.ProductSetbox_con1 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ProductSetbox_con2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ProductSetbox_con1 span {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: rgb(48, 48, 48);
  font-size: 12px;
  margin-right: 8px;
}
.ProductSetbox_con2 span {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: rgb(48, 48, 48);
  font-size: 12px;
  margin-right: 8px;
}
.ProductSetbox_con3 {
  color: rgb(48, 48, 48);
  font-size: 12px;
  border-radius: 8px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  padding: 6px 12px;
  margin-bottom: 8px;
  cursor: pointer;
}
.ProductSetbox_conbox {
  background-color: #fff;
  padding: 10px 0;
  border-radius: 8px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: rgb(48, 48, 48);
  border-color: rgb(48, 48, 48);
}
::v-deep .el-checkbox__inner:hover {
  border-color: rgb(48, 48, 48);
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: rgb(48, 48, 48);
  border-color: rgb(48, 48, 48);
}
.procon {
  display: flex;
  flex-wrap: wrap;
}
.procon_item {
  width: 14%;
  padding: 10px 15px 0;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}
.procon_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: 8px;
}
.procon_item.active {
  background-color: rgba(0, 0, 0, 0.1);
}
.procon_itemimgbox {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.procon_itemimgbox > div {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.04);
  position: absolute;
}
.procon_itemimgbox img {
  width: 100%;
}
.procon {
  height: 475px;
  overflow-y: scroll;
}
/* ::v-deep .el-tabs__content{

} */
.procon_itemtitle {
  margin-top: 16px;
  /* font-weight: 700; */
  font-size: 17px;
  width: 100%;
  line-height: 24px;
  text-align: center;
  /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
}
::v-deep .el-tabs__nav-scroll {
  overflow-y: auto;
}
::v-deep .el-tabs__nav-scroll::-webkit-scrollbar {
  display: none;
}
::v-deep .el-tabs__nav-next {
  opacity: 0;
}
::v-deep .el-tabs__nav-prev {
  opacity: 0;
}
@media (max-width: 684px) {
  .ProductSetbox_con > div {
    margin-right: 0px;
  }
  .ProductSetbox_con1 {
    width: 100%;
  }
  .ProductSetbox_con2 {
    width: 100%;
  }
  .ProductSetbox_con2 .el-select {
    width: 100%;
  }
  .ProductSetbox_con2 span {
    width: 80px;
  }
}
@media (max-width: 486px) {
  .ProductSetbox_con1,
  .ProductSetbox_con2 {
    flex-wrap: wrap;
  }
  .ProductSetbox_con1 span,
  .ProductSetbox_con2 span {
    margin-bottom: 8px;
  }
}
.productsrc {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  box-shadow: rgba(0, 0, 0, 0.08) 0;
  flex-shrink: 0;
  margin-right: 12px;
}
.productsrc img {
  max-width: 100%;
  max-height: 100%;
}
.productsrcbox {
  display: flex;
  align-items: center;
}
.productname {
  font-size: 13px;
  font-weight: 450;
  line-height: 20px;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
}
.Statuscolor {
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 450;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
.pro_con {
  display: none;
  flex-wrap: wrap;
}
.pro_item {
  width: 100%;
}

.pro_left {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.08) 0;
  flex-shrink: 0;
  margin-right: 12px;
}
.pro_item {
  padding: 12px 0;
  border-bottom: 1px solid rgb(227, 227, 227);
  display: flex;
}
.pro_left img {
  max-width: 100%;
  max-height: 100%;
}
.pro_right1 {
  font-size: 14px;
  font-weight: 550;
}
.pro_right > div {
  margin-bottom: 3px;
}
.datanull {
  color: rgb(97, 97, 97);
  text-align: center;
  margin-top: 20px;
}
.pro_right2 {
  font-size: 12px;
  color: rgb(48, 48, 48);
  font-weight: 450;
}
.pro_right3 {
  font-size: 12px;
  color: rgb(97, 97, 97);
  font-weight: 450;
}
.datanull {
  display: none;
}
.submitbut {
  background-color: rgb(48, 48, 48);
  border-color: rgb(48, 48, 48);
}
.submitbut.is-disabled {
  background-color: rgb(48, 48, 48, 0.8);
  border-color: rgb(48, 48, 48, 0.8);
}
@media (max-width: 1664px) {
  .procon_item {
    width: 20%;
  }
}
@media (max-width: 1112px) {
  .procon_item {
    width: 25%;
  }
}
@media (max-width: 1218px) {
  .procon_item {
    width: 25%;
    padding: 10px 10px 0;
  }
  .procon_itemtitle {
    font-size: 15px;
  }
  ::v-deep .el-dialog {
    width: 80% !important;
  }
}
@media (max-width: 910px) {
  .procon_item {
    width: 33%;
  }
  ::v-deep .el-tabs__nav {
    width: 200px;
  }
  ::v-deep .el-tabs__item {
    height: auto !important;
    white-space: wrap !important;
    line-height: normal !important;
    padding: 10px;
  }
  ::v-deep .el-dialog__body {
    padding: 15px 10px;
  }
}
@media (max-width: 678px) {
  ::v-deep .el-tabs__nav {
    width: 100px;
  }
}
@media (max-width: 468px) {
  ::v-deep .el-dialog {
    width: 95% !important;
  }
  ::v-deep .el-tabs__nav {
    width: 80px;
  }
  .procon_itemtitle {
    font-size: 12px;
  }
  .procon_item {
    padding: 5px 5px 0;
  }
  ::v-deep .el-tabs__item {
    font-size: 12px;
    padding-left: 0;
  }
  .procon_itemtitle {
    line-height: 18px;
  }
}
@media (max-width: 475px) {
  .datanull {
    display: block;
  }
  .ProductSetbox {
    padding: 0 12px;
  }
  .ProductSetbox_con3 {
    display: none;
  }
  .table {
    display: none;
  }
  .pro_con {
    display: flex;
  }
}
</style>
